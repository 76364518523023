




































































// @ is an alias to /src
import Vue from 'vue';
import { MetaInfo } from 'vue-meta';
import { settings, types } from '../common/settings';

export default Vue.extend({
  name: 'Settings',
  metaInfo(): MetaInfo {
    return {
      title: 'Settings',
      meta: [
        { name: 'Settings', content: 'Customize your Coderpunk tools experience by changing the theme and color' },
        { property: 'og:title', content: 'Coderpunk tools Settings' },
        { property: 'og:site_name', content: 'Coderpunk tools' },
        { property: 'og:description', content: 'Customize your Coderpunk tools experience by changing the theme and color' },
        { property: 'og:type', content: 'webapp'},
        { property: 'og:url', content: 'https://tools.coderpunk.tech/#/settings' },
        { property: 'og:image', content: '@/assets/Coderpunk.png' }    
      ]
    }
  },
  data: () => ({
    themes: settings.valueForOrDefault(types.THEME, []),
    editorThemes: settings.valueForOrDefault(types.EDITOR_COLOR, []),
    colors: settings.valueForOrDefault(types.COLOR, []),
    selectedColor: settings.loadOrDefault(types.COLOR, 'pink'),
    selectedTheme: settings.loadOrDefault(types.THEME, 'dark'),
    selectedEditorTheme: settings.loadOrDefault(types.EDITOR_COLOR, 'base16-dark')
  }),
  methods: {
    updateTheme(): void {
      if (this.selectedTheme === 'dark') {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
      this.$store.commit('setTheme', this.selectedTheme);
    },
    updateColor(color: string) {
      this.$store.commit('setColor', color);
    },
    updateEditorTheme() {
      this.$store.commit('setEditorTheme', this.selectedEditorTheme);
    },
    getColorClass(hover: boolean, color: string) {
      let colorClass = 'color-card';

      if (hover) {
        colorClass += ' on-hover';
      }

      if (color === this.$store.state.color) {
        colorClass += ' selected-color';
      }
      return colorClass;
    }
  }
})
